//// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-filter-facet').length) {
    filter_init();
  }

  // ********** **********
  function filter_apply(filterData) {
    if (!filterData.isCloseApplyMode) {
      return;
    }

    filterData.formElem.submit();
  }

  // ********** **********
  function filter_createData() {
    const parentElem = $('.js-filter');
    const dropdownElem = $('.js-filter-dropdown', parentElem);
    const dropdownClose1Btn = $('.js-filter-dropdown-close1', dropdownElem);
    const dropdownClose2Btn = $('.js-filter-dropdown-close2', dropdownElem);
    const dropdownClose2ApplyElem = $('.js-filter-dropdown-close-apply', dropdownClose2Btn);
    const dropdownClose2CancelElem = $('.js-filter-dropdown-close-cancel', dropdownClose2Btn);
    const dropdownHideInputElem = $('.js-filter-dropdown-hide-input', dropdownElem);
    const formElem = $('.js-form-filter');

    const filterState = filter_loadState();
    const tabletMediaQuery = window.matchMedia('(max-width: 991px)');

    const body = $('body');

    const filterData = {
      body: body,
      dropdownClose1Btn: dropdownClose1Btn,
      dropdownClose2ApplyElem: dropdownClose2ApplyElem,
      dropdownClose2Btn: dropdownClose2Btn,
      dropdownClose2CancelElem: dropdownClose2CancelElem,
      dropdownElem: dropdownElem,
      dropdownHideInputElem: dropdownHideInputElem,
      facets: [],
      formElem: formElem,
      isCloseApplyMode: false,
      isTabletSizeWindow: tabletMediaQuery.matches,
      parentElem: parentElem,
      state: filterState
    };

    dropdownClose1Btn.click(() => {
      dropdownClose1Btn.blur();
    });

    dropdownClose2Btn.click(() => {
      dropdownClose2Btn.blur();
      filter_onDropdownCloseClick(filterData);
      filter_apply(filterData);
    });

    tabletMediaQuery.addEventListener('change', (event) => {
      const newIsTabletSizeWindow = event.matches;
      if (filterData.isTabletSizeWindow != newIsTabletSizeWindow) {
        filterData.isTabletSizeWindow = newIsTabletSizeWindow;
        filter_onIsTabletSizeWindowChanged(filterData);
      }
    });

    dropdownClose2ApplyElem.hide();
    dropdownClose2CancelElem.show();

    return filterData;
  }

  // ********** **********
  function filter_init() {

    const filterData = filter_createData();

    $('.js-filter-facet', filterData.parentElem).each((i, elem) => {
      const filterFacetElem = $(elem);
      const facet = filterFacet_init(filterFacetElem, filterData);
      filterData.facets.push(facet);
    });

    filter_onIsTabletSizeWindowChanged(filterData);
    return filterData;
  }

  // ********** **********
  function filter_loadState() {
    const stateStr = localStorage != null ? localStorage.facets : null;
    const state1 = stateStr != null ? JSON.parse(stateStr) : null;
    const state2 = state1 != null ? state1 : {};
    return state2;
  }

  // ********** **********
  function filter_onDropdownCloseClick(filterData) {
    if (filterData.isTabletSizeWindow) {
      filterData.facets.forEach((facet) => {
        filterFacet_showHide(facet, false, true);
      });
    }
  }

  // ********** **********
  function filter_onIsTabletSizeWindowChanged(filterData) {
    if (filterData.isTabletSizeWindow) {
      filterData.facets.forEach((facet) => {
        filterFacet_showHide(facet, false, true);
      });

      filterData.dropdownElem.css({ top: '' });
    }
    else {
      filterData.facets.forEach((facet) => {
        if (filterData.state[facet.code]) {
          filterFacet_showHide(facet, true, true);
        }
      });
    }
  }

  // ********** **********
  function filter_saveState(filterData) {

    if (localStorage == null) {
      return;
    }

    localStorage.facets = JSON.stringify(filterData.state);
  }

  // ********** **********
  function filter_updateDropdown(filterData, event) {

    if (!filterData.isTabletSizeWindow && filterData.parentElem.length > 0) {
      const targetCoords = filterData.parentElem[0].getBoundingClientRect();
      const yCoord = event.clientY - targetCoords.top;

      filterData.dropdownElem.css({ top: `${Math.round(+yCoord)}px` });
      filterData.dropdownHideInputElem.focus();
    }

    filterData.isCloseApplyMode = true;
    filterData.dropdownClose2ApplyElem.show();
    filterData.dropdownClose2CancelElem.hide();
  }

  // ********** **********
  function filterFacet_init(elem, filterData) {
    const headBtn = $('.js-filter-facet-head', elem);
    const contentElem = $('.js-filter-facet-content', elem);
    const contentCloseBtn = $('.js-filter-facet-content-close', elem);
    const contentLoadElem = $('.js-filter-facet-content-load', elem);

    const code = elem.attr('data-code');
    const isActive = elem.attr('data-active') == '1';
    const isDefaultVisible = elem.attr('data-default-visible') == '1';
    const url = elem.attr('data-url');

    var facet = {
      code: code,
      contentElem: contentElem,
      contentLoadElem: contentLoadElem,
      elem: elem,
      filterData: filterData,
      isActive: isActive,
      isDefaultVisible: isDefaultVisible,
      isOpened: false,
      url: url
    };

    if (isActive || isDefaultVisible && filterData.state[code] != false) {
      filterData.state[code] = true;
    }

    contentCloseBtn.click(() => {
      filterFacet_showHide(facet, false, true);
      filterFacet_apply(filterData);
    });

    headBtn.click(() => {
      filterFacet_onHeaderClick(facet);
    });

    return facet;
  }

  // ********** **********
  function filterFacet_load(facet) {
    if (facet.isLoaded) {
      return;
    }

    if (facet.url == null) {
      return;
    }

    facet.isLoaded = true;
    facet.contentLoadElem.load(facet.url, () => { filterFacet_load_result(facet); });
  }

  // ********** **********
  function filterFacet_load_result(facet) {
    const checkboxElems = $('.js-filter-checkbox', facet.contentElem);

    checkboxElems.click((event) => {
      filterFacet_onCheckboxClick(facet, event);
    });
  }

  // ********** **********
  function filterFacet_onCheckboxClick(facet, event) {
    filter_updateDropdown(facet.filterData, event);
  }

  // ********** **********
  function filterFacet_onHeaderClick(facet) {
    filterFacet_showHide(facet, !facet.isOpened, false);
  }

  // ********** **********
  function filterFacet_showHide(facet, isVisible, instantly) {

    if (facet.isOpened == isVisible) {
      return;
    }

    elibUtils.expand(facet, isVisible, { instantly: instantly });

    const filterData = facet.filterData;
    if (!filterData.isTabletSizeWindow) {
      filterData.state[facet.code] = facet.isOpened;
      filter_saveState(filterData);
    }
    else {
      const body = facet.filterData.body;
      if (isVisible) {
        body.css({ 'overflow-y': 'hidden' });
      }
      else {
        body.css({ 'overflow-y': '' });
      }
    }

    if (isVisible) {
      filterFacet_load(facet);
    }
  }
});
