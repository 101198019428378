// ********** ********** ********** ********** **********

// ********** **********
$(document).ready(() => {
  // mob menu
  const body = $('body');
  const headerMain = $('.js-header-main');
  const headerMenu = $('.js-header-menu');
  const btnMenuBurger = $('.js-burger');

  btnMenuBurger.click(() => {
    body.toggleClass('scroll-lock');
    headerMain.toggleClass('active');
    headerMenu.toggleClass('active');
    btnMenuBurger.toggleClass('active');
  });

  // add padding right desktop browsers
  //const userAgent = navigator.userAgent;
  //if (userAgent.indexOf('Windows') != -1) {
  //  body.css({ paddingRight: `${window.innerWidth - document.documentElement.clientWidth}px` });
  //}

  // close lang
  const langParents = $('.js-lang');
  langParents.each((i, elem) => {
    const langParent = $(elem);
    const langHeadBtn = $('.js-lang-btn', langParent);

    langParent.click((event) => {
      if ($(event.target).hasClass('js-lang-list')) {
        langParent.addClass('active');
      }
    });

    langHeadBtn.blur(() => {
      langParent.removeClass('active');
    });

    langHeadBtn.click(() => {
      if (langParent.hasClass('active')) {
        langHeadBtn.blur();
      }
      else {
        langParent.addClass('active');
      }
    });
  });
});

// ********** **********
// a fix for the rare cases when the accordion on the phone appears when the page loads
$(window).on('load', () => {
  setTimeout(() => {
    $('.js-header-menu').addClass('loaded');
  }, 500);
});




