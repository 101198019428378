//// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-favorite-panel').length) {
    init();
  }

  // ********** **********
  function init() {
    const stateImg = $('#favoritePanelImageState');
    const waitImg = $('#favoritePanelImageWait');

    const initData = {
      stateImg: stateImg,
      waitImg: waitImg
    };

    $('.js-favorite-panel').each((i, elem) => {
      const favoritePanelBtn = $(elem);
      initFavoritePanel(favoritePanelBtn, initData);
    });
  }

  // ********** **********
  function initFavoritePanel(favoritePanelBtn, initData) {
    const url = favoritePanelBtn.attr('data-url');
    const favoritePanel = {
      btn: favoritePanelBtn,
      initData: initData,
      isaActive: null,
      isDisabled: true,
      url: url
    };

    favoritePanelBtn.click(() => {
      onFavoritePanelClick(favoritePanel);
    });

    refreshFavoritePanel(favoritePanel);
    return favoritePanel;
  }

  // ********** **********
  function onFavoritePanelClick(favoritePanel) {
    if (favoritePanel.isDisabled) {
      return;
    }

    favoritePanel.isDisabled = true;
    updateFavoritePanel(favoritePanel);

    const action = favoritePanel.isActive ? 'pcremovefromfavorites' : 'pcaddtofavorites';
    const newIsActive = !favoritePanel.isActive;

    $.post(
      baseUrl + 'api/v1/' + action,
      { url: favoritePanel.url },
      (res) => { onFavoritePanelClick_result(favoritePanel, res, newIsActive); });
  }

  // ********** **********
  function onFavoritePanelClick_result(favoritePanel, res, newIsActive) {
    if (!res.ok) {
      updateFavoritePanel_showError(favoritePanel, res.message);
      return;
    }

    favoritePanel.isDisabled = false;
    favoritePanel.isActive = newIsActive;
    updateFavoritePanel(favoritePanel);
  }

  // ********** **********
  function refreshFavoritePanel(favoritePanel) {
    favoritePanel.isDisabled = true;
    updateFavoritePanel(favoritePanel);

    $.post(
      baseUrl + 'api/v1/pcisinfavorites',
      { url: favoritePanel.url },
      (res) => { refreshFavoritePanel_result(favoritePanel, res); });
  }

  // ********** **********
  function refreshFavoritePanel_result(favoritePanel, res) {
    if (!res.ok) {
      updateFavoritePanel_showError(favoritePanel, res.message);
      return;
    }

    favoritePanel.isDisabled = false;
    favoritePanel.isActive = res.result;
    updateFavoritePanel(favoritePanel);
  }

  // ********** **********
  function updateFavoritePanel(favoritePanel) {

    favoritePanel.btn.empty();
    const initData = favoritePanel.initData;
    if (favoritePanel.isDisabled) {
      favoritePanel.btn.attr('disabled', '');
      initData.waitImg.clone().removeAttr('id').appendTo(favoritePanel.btn);
    }
    else {
      favoritePanel.btn.removeAttr('disabled');
      elibUtils.addRemoveClass(favoritePanel.btn, 'active', favoritePanel.isActive);
      initData.stateImg.clone().removeAttr('id').appendTo(favoritePanel.btn);
    }
  }

  // ********** **********
  function updateFavoritePanel_showError(favoritePanel, message) {
    favoritePanel.btn.attr('disabled', '');
    favoritePanel.btn.text(message);
  }
});
