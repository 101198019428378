// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-pc-collections-content').length) {
    collections_init();
  }

  if ($('.js-pc-queries-content').length) {
    queries_init();
  }

  if ($('.js-pc-single-collection-content').length) {
    single_collection_init();
  }

  // ********** **********
  function collection_closeAllPanels(collection) {

    elibUtils.expand(collection.editor, false);

    Object.keys(collection.documents).forEach((documentId) => {

      const documentData = collection.documents[documentId];
      elibUtils.expand(documentData.editor, false);
      elibUtils.expand(documentData.movePanel, false);
      elibUtils.expand(documentData.removePanel, false);
    });
  }

  // ********** **********
  function collection_create(collectionsData, resCollection) {

    const elem = collectionsData.collectionTemplateElem.clone().removeAttr('id');
    elem.appendTo(collectionsData.contentElem);

    const headBtn = $('.js-pc-collection-head', elem);
    const headNameElem = $('.js-pc-collection-head-name', elem);
    const headCommentsElem = $('.js-pc-collection-head-comments', elem);
    const headDocumentsCountElem = $('.js-pc-collection-head-documents-count', elem);
    const headActiveElem = $('.js-pc-collection-head-active', elem);
    const headLinkElem = $('.js-pc-collection-head-link', elem);

    const editorElem = $('.js-pc-collection-content-head', elem);
    const editorContentElem = $('.js-pc-collection-content-head-editor', elem);
    const messageElem = $('.js-pc-collection-content-head-message', elem);

    const contentElem = $('.js-pc-collection-content', elem);
    const documentsElem = $('.js-pc-collection-documents', elem);

    const editBtn = $('.js-pc-collection-edit', elem);
    const removeBtn = $('.js-pc-collection-remove', elem);
    const removeOkBtn = $('.js-pc-collection-remove-ok', elem);
    const removeCancelBtn = $('.js-pc-collection-remove-cancel', elem);

    const collection = {
      collectionsData: collectionsData,
      contentElem: contentElem,
      documents: {},
      documentsCount: resCollection.documentsCount,
      documentsElem: documentsElem,
      editBtn: editBtn,
      editor: {
        elem: editorElem,
        contentElem: editorContentElem,
        isOpened: false,
        values: {
          comments: resCollection.comments,
          isActive: resCollection.isActive,
          name: resCollection.name
        }
      },
      elem: elem,
      headActiveElem: headActiveElem,
      headBtn: headBtn,
      headCommentsElem: headCommentsElem,
      headLinkElem: headLinkElem,
      headNameElem: headNameElem,
      headDocumentsCountElem: headDocumentsCountElem,
      id: resCollection.id,
      isItemsLoaded: false,
      isOpened: false,
      messageElem: messageElem,
      removeBtn: removeBtn,
      removeOkBtn: removeOkBtn,
      removeCancelBtn: removeCancelBtn
    };

    collection_updateHead(collection);

    editBtn.click(() => { collection_onEditClick(collection); });

    headBtn.click(() => { collection_onHeadClick(collection); });

    removeOkBtn.click(() => { collection_remove_ok(collection); });
    removeCancelBtn.click(() => { collection_remove_cancel(collection); });

    documentsElem.sortable({
      disabled: false,
      start: (event, ui) => { collection_onSortableStart(collection, event, ui); },
      stop: (event, ui) => { collection_onSortableStop(collection, event, ui); }
    });

    if (resCollection.isActive) {
      collection_expand(collection, true);
    }

    return collection;
  }

  // ********** **********
  function collection_editor_cancel(collection) {
    elibUtils.expand(collection.editor, false);
  }

  // ********** **********
  function collection_editor_ok(collection) {
    const editor = collection.editor;

    $('button, input, textarea', collection.elem).prop('disabled', true);
    collection.messageElem.empty();

    const postData = elibUtils.editor_getValues(editor.contentInnerElem);

    function onDone() {
      $('button, input, textarea', collection.elem).prop('disabled', false);
    }

    if (collection.id >= 0) {
      postData.id = collection.id;

      $.post(
        baseUrl + 'api/v1/pcupdatecollection',
        postData,
        (res) => { collection_editor_ok_update_result(collection, res, postData); })
        .done(onDone);
    }
    else {
      $.post(
        baseUrl + 'api/v1/pcaddcollection',
        postData,
        (res) => { collection_editor_ok_add_result(collection, res, postData); })
        .done(onDone);
    }
  }

  // ********** **********
  function collection_editor_ok_add_result(collection, res, postData) {
    if (!res.ok) {
      collection.messageElem.text(res.message);
      return;
    }

    collection.documentsCount = 0;
    collection.id = res.id;

    collection.collectionsData.collections[res.id] = collection;

    elibUtils.showHide(collection.collectionsData.addBtn, res.allowNewCollection);

    collection_editor_ok_result2(collection, res, postData);
  }

  // ********** **********
  function collection_editor_ok_update_result(collection, res, postData) {
    if (!res.ok) {
      collection.messageElem.text(res.message);
      return;
    }

    collection_editor_ok_result2(collection, res, postData);
  }

  // ********** **********
  function collection_editor_ok_result2(collection, res, postData) {

    collection.editor.values.comments = postData.comments;
    collection.editor.values.isActive = postData.isActive;
    collection.editor.values.name = postData.name;

    elibUtils.showHide(collection.collectionsData.addBtn, res.allowNewCollection);

    collection_updateHead(collection);
    elibUtils.expand(collection.editor, false);

    if (newValues.isActive) {
      const cols = collection.collectionsData.collections;
      const id = collection.id;
      Object.keys(cols).forEach((colId) => {
        if (colId == id) {
          return;
        }

        const col = cols[colId];
        if (!col.editor.values.isActive) {
          return;
        }

        col.editor.values.isActive = false;
        collection_updateHead(col);
        elibUtils.expand(col.editor, false);
      });
    }
  }

  // ********** **********
  function collection_expand(collection, isOpened) {

    elibUtils.expand(collection, isOpened);

    if (isOpened && !collection.isItemsLoaded) {
      collection.isItemsLoaded = true;

      if (collection.id >= 0) {
        collection_loadDocuments(collection);
      }
      else {
        collection.documentsElem.empty();
      }
    }
  }

  // ********** **********
  function collection_loadDocuments(collection, onSuccess) {
    $.post(
      baseUrl + 'api/v1/pcgetdocuments',
      { collectionId: collection.id, info: true },
      (res) => { collection_loadDocuments_result(collection, res, onSuccess); });
  }

  // ********** **********
  function collection_loadDocuments_result(collection, res, onSuccess) {

    collection.documentsElem.empty();
    collection.documents = {};

    if (!res.ok) {
      $('<li>', { text: res.message }).appendTo(collection.documentsElem);
    }
    else {
      collection.documentsCount = res.documents.length;
      collection_updateHead(collection);

      res.documents.forEach((resDocument) => {
        const documentData = document_create(collection, resDocument);
        collection.documents[documentData.id] = documentData;
      });

      if (onSuccess) {
        onSuccess();
      }
    }
  }

  // ********** **********
  function collection_onEditClick(collection) {
    const editor = collection.editor;
    if (editor.isOpened) {
      return;
    }

    collection_closeAllPanels(collection);

    if (!editor.isCreated) {
      editor.isCreated = true;
      editor.contentElem.empty();

      editor.contentInnerElem = collection.collectionsData.collectionEditInnerTemplateElem.clone().removeAttr('id');
      editor.contentInnerElem.appendTo(editor.contentElem);

      elibUtils.editor_init(editor.contentInnerElem);

      const cancelBtn = $('.js-pc-collection-editor-cancel', editor.contentInnerElem);
      const okBtn = $('.js-pc-collection-editor-ok', editor.contentInnerElem);
      cancelBtn.click(() => { collection_editor_cancel(collection); });
      okBtn.click(() => { collection_editor_ok(collection); });
    }

    elibUtils.editor_fillValues(editor.contentInnerElem, collection.editor.values);
    elibUtils.expand(editor, true);
  }

  // ********** **********
  function collection_onHeadClick(collection) {
    collection_expand(collection, !collection.isOpened);
  }
  
  // ********** **********
  function collection_onSortableStop(collection, event, ui) {

    const documentData = findDocumentByElem(collection, ui.item);
    if (documentData == null) {
      return;
    }

    const newPosition = ui.item.index();
    document_moveInCollection(documentData, newPosition);
  }

  // ********** **********
  function collection_onSortableStart(collection, event, ui) {

    const documentData = findDocumentByElem(collection, ui.item);
    if (documentData == null) {
      return;
    }

    elibUtils.expand(documentData.editor, false);
    elibUtils.expand(documentData.movePanel, false);
  }

  // ********** **********
  function collection_remove_cancel(collection) {
    collection.removeCancelBtn.blur();
  }

  // ********** **********
  function collection_remove_ok(collection) {
    collection.removeOkBtn.blur();

    $('button, input, textarea', collection.elem).prop('disabled', true);
    collection.messageElem.empty();

    function onDone() {
      $('button, input, textarea', collection.elem).prop('disabled', false);
    }

    $.post(
      baseUrl + 'api/v1/pcremovecollection',
      { id: collection.id },
      (res) => { collection_remove_onOk_result(collection, res); })
      .done(onDone);
  }

  // ********** **********
  function collection_remove_onOk_result(collection, res) {
    if (!res.ok) {
      collection.messageElem.text(res.message);
      return;
    }

    collection.documentsElem.sortable('destroy');
    collection.elem.remove();

    const collections = collection.collectionsData.collections;
    delete collections[collection.id];

    elibUtils.showHide(collection.collectionsData.addBtn, res.allowNewCollection);
  }

  // ********** **********
  function collection_updateHead(collection) {
    if (!collection.editor) {
      return;
    }

    const values = collection.editor.values;
    collection.headActiveElem.text(values.isActive ? '*' : '');
    collection.headNameElem.text(values.name);
    collection.headCommentsElem.text(values.comments);
    collection.headDocumentsCountElem.text(collection.documentsCount);

    if (collection.id >= 0) {
      const colUrl = baseUrl2 + 'pc/collection/' + collection.id;
      collection.headLinkElem.attr('href', colUrl);
      collection.headLinkElem.css({ 'display': '' });
    }
    else {
      collection.headLinkElem.attr('href', '#');
      collection.headLinkElem.css({ 'display': 'none' });
    }
  }

  // ********** **********
  function collections_create_data() {
    const documentTemplateElem = $('#documentTemplate');
    const documentEditInnerTemplateElem = $('#documentEditInnerTemplate');
    const documentMoveInnerTemplateElem = $('#documentMoveInnerTemplate');
    const documentRemoveInnerTemplateElem = $('#documentRemoveInnerTemplate');

    const collectionTemplateElem = $('#collectionTemplate');
    const collectionEditInnerTemplateElem = $('#collectionEditInnerTemplate');
    const contentElem = $('.js-pc-collections-content');
    const addBtn = $('.js-pc-collections-add');

    const collectionsData = {
      addBtn: addBtn,
      collectionEditInnerTemplateElem: collectionEditInnerTemplateElem,
      collectionTemplateElem: collectionTemplateElem,
      collections: {},
      contentElem: contentElem,
      documentEditInnerTemplateElem: documentEditInnerTemplateElem,
      documentMoveInnerTemplateElem: documentMoveInnerTemplateElem,
      documentRemoveInnerTemplateElem: documentRemoveInnerTemplateElem,
      documentTemplateElem: documentTemplateElem
    };

    return collectionsData;
  }

  // ********** **********
  function collections_init() {
    const collectionsData = collections_create_data();
    collectionsData.addBtn.click(() => { collections_onAddClick(collectionsData); });
    collections_load_all(collectionsData);
  }

  // ********** **********
  function collections_load_all(collectionsData) {
    $.post(
      baseUrl + 'api/v1/pcgetcollections',
      null,
      (res) => { collections_load_all_result(collectionsData, res); });
  }

  // ********** **********
  function collections_load_all_result(collectionsData, res) {
    if (!res.ok) {
      collectionsData.contentElem.text(res.message);
      return;
    }

    collectionsData.contentElem.empty();

    elibUtils.showHide(collectionsData.addBtn, res.allowNewCollection);

    res.collections.forEach((resCollection) => {
      const collection = collection_create(collectionsData, resCollection);
      collectionsData.collections[resCollection.id] = collection;
    });
  }

  // ********** **********
  function collections_onAddClick(collectionsData) {
    const resCollection = { documentsCount: 0, name: '' };
    const collection = collection_create(collectionsData, resCollection);
    collection_expand(collection, true);
    collection_onEditClick(collection);
  }

  // ********** **********
  function document_create(collection, resDocument) {
    const elem = collection.collectionsData.documentTemplateElem.clone().removeAttr('id');
    elem.appendTo(collection.documentsElem);

    const nameElem = $('.js-pc-document-name', elem);
    const commentsElem = $('.js-pc-document-comments', elem);
    const messageElem = $('.js-pc-document-message', elem);

    const editBtn = $('.js-pc-document-edit', elem);
    const moveBtn = $('.js-pc-document-move', elem);
    const removeBtn = $('.js-pc-document-remove', elem);

    const editPanelElem = $('.js-pc-document-edit-content', elem);
    const movePanelElem = $('.js-pc-document-move-content', elem);
    const removePanelElem = $('.js-pc-document-remove-content', elem);

    nameElem.text(resDocument.name);
    nameElem.attr('href', resDocument.infoUrl);

    commentsElem.text(resDocument.comments);

    const documentData = {
      collection: collection,
      commentsElem: commentsElem,
      id: resDocument.id,
      editBtn: editBtn,
      editor: {
        elem: elem,
        contentElem: editPanelElem,
        openClass: 'openEdit',
        values: {
          comments: resDocument.comments
        }
      },
      elem: elem,
      messageElem: messageElem,
      moveBtn: moveBtn,
      movePanel: {
        elem: elem,
        contentElem: movePanelElem,
        openClass: 'openMove'
      },
      removePanel: {
        elem: elem,
        contentElem: removePanelElem,
        openClass: 'openRemove'
      },
      nameElem: nameElem,
      removeBtn: removeBtn
    };

    editBtn.click(() => { document_onEditClick(documentData); });
    moveBtn.click(() => { document_onMoveClick(documentData); });
    removeBtn.click(() => { document_onRemoveClick(documentData); });

    return documentData;
  }

  // ********** **********
  function document_edit_cancel(documentData) {
    elibUtils.expand(documentData.editor, false);
  }

  // ********** **********
  function document_edit_ok(documentData) {
    const editor = documentData.editor;

    document_enableInputElements(documentData, false);
    documentData.messageElem.empty();

    const postData = elibUtils.editor_getValues(editor.contentInnerElem);
    postData.documentId = documentData.id;
    postData.collectionId = documentData.collection.id;

    function onDone() {
      document_enableInputElements(documentData, true);
    }

    $.post(
      baseUrl + 'api/v1/pcupdatedocument',
      postData,
      (res) => { document_edit_ok_result(documentData, res, postData); })
      .done(onDone);
  }

  // ********** **********
  function document_edit_ok_result(documentData, res, postData) {
    if (!res.ok) {
      documentData.messageElem.text(res.message);
      return;
    }

    documentData.editor.values.comments = postData.comments;
    documentData.commentsElem.text(postData.comments);

    elibUtils.expand(documentData.editor, false);
  }

  // ********** **********
  function document_enableInputElements(documentData, isEnabled) {
    $('button, input, textarea', documentData.elem).prop('disabled', !isEnabled);
    documentData.collection.documentsElem.sortable('option', 'disabled', !isEnabled);
  }

  // ********** **********
  function document_moveInCollection(documentData, newPosition) {
    document_enableInputElements(documentData, false);
    documentData.messageElem.empty();

    function onDone() {
      document_enableInputElements(documentData, true);
    }

    const postData = {
      collectionId: documentData.collection.id,
      documentId: documentData.id,
      newPosition: newPosition
    };

    $.post(
      baseUrl + 'api/v1/pcmovedocumentincollection',
      postData,
      (res) => { document_moveInCollection_result(documentData, res); })
      .done(onDone);
  }

  // ********** **********
  function document_moveInCollection_result(documentData, res) {
    if (!res.ok) {
      documentData.messageElem.text(res.message);
      return;
    }
  }

  // ********** **********
  function document_moveToCollection(documentData, newCollection) {
    document_enableInputElements(documentData, false);
    documentData.messageElem.empty();

    function onDone() {
      document_enableInputElements(documentData, true);
    }

    const postData = {
      collectionId: documentData.collection.id,
      documentId: documentData.id,
      newCollectionId: newCollection.id
    };

    $.post(
      baseUrl + 'api/v1/pcmovedocumenttocollection',
      postData,
      (res) => { document_moveToCollection_result(documentData, res, newCollection); })
      .done(onDone);
  }

  // ********** **********
  function document_moveToCollection_result(documentData, res, newCollection) {
    if (!res.ok) {
      documentData.messageElem.text(res.message);
      return;
    }

    documentData.elem.remove();

    const collection = documentData.collection;
    collection.documentsCount--;
    collection_updateHead(collection);

    collection_loadDocuments(newCollection);
  }

  // ********** **********
  function document_onEditClick(documentData) {
    const editor = documentData.editor;
    if (editor.isOpened) {
      elibUtils.expand(editor, false);
      return;
    }

    collection_closeAllPanels(documentData.collection);

    const collectionsData = documentData.collection.collectionsData;

    if (!editor.isCreated) {
      editor.isCreated = true;
      editor.contentElem.empty();

      editor.contentInnerElem = collectionsData.documentEditInnerTemplateElem.clone().removeAttr('id');
      editor.contentInnerElem.appendTo(editor.contentElem);

      elibUtils.editor_init(editor.contentInnerElem);

      const cancelBtn = $('.js-pc-document-edit-cancel', editor.contentInnerElem);
      const okBtn = $('.js-pc-document-edit-ok', editor.contentInnerElem);
      cancelBtn.click(() => { document_edit_cancel(documentData); });
      okBtn.click(() => { document_edit_ok(documentData); });
    }

    elibUtils.editor_fillValues(editor.contentInnerElem, editor.values);
    elibUtils.expand(editor, true);
  }

  // ********** **********
  function document_onMoveClick(documentData) {
    const movePanel = documentData.movePanel;
    if (movePanel.isOpened) {
      elibUtils.expand(movePanel, false);
      return;
    }

    collection_closeAllPanels(documentData.collection);

    const collectionsData = documentData.collection.collectionsData;

    if (!movePanel.isCreated) {
      movePanel.isCreated = true;
      movePanel.contentElem.empty();

      const innerContentElem = collectionsData.documentMoveInnerTemplateElem.clone().removeAttr('id');
      innerContentElem.appendTo(movePanel.contentElem);

      movePanel.optionsElem = $('.js-pc-document-move-options', innerContentElem);
    }

    movePanel.optionsElem.empty();
    const collectionsIds = Object.keys(collectionsData.collections);
    collectionsIds.forEach((newCollectionId) => {
      const newCollection = collectionsData.collections[newCollectionId];
      if (newCollection == documentData.collection) {
        return;
      }

      const name = newCollection.editor.values.name;
      const btn = $('<button>', { 'class': 'btn btn--grey', text: name });
      btn.appendTo(movePanel.optionsElem);
      btn.click(() => { document_moveToCollection(documentData, newCollection);});
    });

    elibUtils.expand(movePanel, true);
  }

  // ********** **********
  function document_onRemoveClick(documentData) {
    const removePanel = documentData.removePanel;
    if (removePanel.isOpened) {
      elibUtils.expand(removePanel, false);
      return;
    }

    collection_closeAllPanels(documentData.collection);

    const collectionsData = documentData.collection.collectionsData;

    if (!removePanel.isCreated) {
      removePanel.isCreated = true;
      removePanel.contentElem.empty();

      const innerContentElem = collectionsData.documentRemoveInnerTemplateElem.clone().removeAttr('id');
      innerContentElem.appendTo(removePanel.contentElem);

      const cancelBtn = $('.js-pc-document-remove-cancel', removePanel.contentElem);
      const okBtn = $('.js-pc-document-remove-ok', removePanel.contentElem);
      cancelBtn.click(() => { document_remove_cancel(documentData); });
      okBtn.click(() => { document_remove_ok(documentData); });
    }

    elibUtils.expand(removePanel, true);
  }

  // ********** **********
  function document_remove_cancel(documentData) {
    elibUtils.expand(documentData.removePanel, false);
  }

  // ********** **********
  function document_remove_ok(documentData) {

    document_enableInputElements(documentData, false);
    documentData.messageElem.empty();

    const postData = {
      collectionId: documentData.collection.id,
      documentId: documentData.id
    };

    function onDone() {
      document_enableInputElements(documentData, true);
    }

    $.post(
      baseUrl + 'api/v1/pcremovedocument',
      postData,
      (res) => { document_remove_ok_result(documentData, res); })
      .done(onDone);
  }

  // ********** **********
  function document_remove_ok_result(documentData, res) {
    if (!res.ok) {
      documentData.messageElem.text(res.message);
      return;
    }

    documentData.elem.remove();

    const collection = documentData.collection;
    collection.documentsCount--;
    collection_updateHead(collection);
  }

  // ********** **********
  function queries_init() {
    const contentElem = $('.js-pc-queries-content');
    const removeBtn = $('.js-pc-queries-remove');

    const queriesData = {
      contentElem: contentElem,
      removeBtn: removeBtn
    };

    removeBtn.click(() => { queries_remove(queriesData); });

    queries_load(queriesData);
  }

  // ********** **********
  function queries_load(queriesData) {
    $.post(
      baseUrl + 'api/v1/pcgetqueries',
      null,
      (res) => { queries_load_result(queriesData, res); });
  }

  // ********** **********
  function queries_load_result(queriesData, res) {
    if (!res.ok) {
      queriesData.contentElem.text(res.message);
      return;
    }

    queriesData.contentElem.empty();
    var ulElem = $('<ul>', { 'class': 'cabinet__list' }).appendTo(queriesData.contentElem);

    const hasQueries = res.queries != null && res.queries.length > 0;
    if (hasQueries) {
      res.queries.forEach((query) => {
        const liElem = $('<li>').appendTo(ulElem);
        const href = baseUrl + 'search/result?q=' + encodeURIComponent(query);
        const a = $('<a>', { 'class': 'link', href: href, text: query });
        a.appendTo(liElem);

        if (query.length == 0) {
          a.html('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
        }
      });
    }

    elibUtils.showHide(queriesData.removeBtn, hasQueries);
  }

  // ********** **********
  function queries_remove(queriesData) {
    $.post(
      baseUrl + 'api/v1/pcremovequeries',
      null,
      (res) => { queries_remove_result(queriesData, res); });
  }

  // ********** **********
  function queries_remove_result(queriesData, res) {
    if (!res.ok) {
      queriesData.contentElem.text(res.message);
      return;
    }

    queriesData.contentElem.empty();
    queriesData.removeBtn.hide();
  }

  // ********** **********
  function single_collection_init() {

    const elem = $('.js-pc-single-collection-content');
    const id = elem.attr('data-collection-id');
    const documentsElem = $('.js-pc-collection-documents', elem);

    const collectionsData = collections_create_data();

    const collection = {
      collectionsData: collectionsData,
      documentsElem: documentsElem,
      elem: elem,
      id: id
    };

    documentsElem.sortable({
      disabled: false,
      start: (event, ui) => { collection_onSortableStart(collection, event, ui); },
      stop: (event, ui) => { collection_onSortableStop(collection, event, ui); }
    });

    collection_loadDocuments(collection, () => {
      single_collection_init2(collectionsData, collection);
    });
  }

  // ********** **********
  function single_collection_init2(collectionsData, collection) {
    $.post(
      baseUrl + 'api/v1/pcgetcollections',
      null,
      (res) => { single_collection_init2_result(collectionsData, collection, res); });
  }

  // ********** **********
  function single_collection_init2_result(collectionsData, collection, res) {
    if (!res.ok) {
      return;
    }

    res.collections.forEach((resCollection) => {
      let col2 = collection;
      if (resCollection.id != col2.id) {
        col2 = { id: resCollection.id };
      }

      col2.editor = {
        values: { name: resCollection.name }
      };

      collectionsData.collections[resCollection.id] = col2;
    });
  }

  // ********** **********
  function findDocumentByElem(collection, elem) {
    const documentIds = Object.keys(collection.documents);
    let documentData;
    for (let i = 0; i < documentIds.length; i++) {
      documentData = collection.documents[documentIds[i]];
      if (documentData.elem[0] == elem[0]) {
        return documentData;
      }
    }

    return null;
  }
});
