//// ********** ********** ********** ********** **********

// ********** **********
function sendMessage_init() {

  // ********** **********
  $(document).ready(() => {
    init();
  });

  // ********** **********
  function init() {

    const btn = $('.js-send-message-button');
    const formElem = $('.js-send-message-form');
    const messageElem = $('.js-send-message-message');
    const protectionCodeElem = $('input[name = "protectionCode"]');
    const allInputElems = $(".js-send-message-form :input");

    const data = {
      allInputElems: allInputElems,
      btn: btn,
      formElem: formElem,
      messageElem: messageElem,
      protectionCodeElem: protectionCodeElem
    };

    btn.click(() => {
      processSubmit(data);
    });
  }

  // ********** **********
  function processSubmit(data) {
    showMessage(data, null);
    data.allInputElems.prop('disabled', true);

    $.post(
      baseUrl + 'main/getprotectioncode',
      {},
      (res) => { processSubmit_result(data, res); });
  }

  // ********** **********
  function processSubmit_result(data, res) {
    data.allInputElems.prop('disabled', false);

    if (!res.ok) {
      showMessage(data, res.message);
      return;
    }

    data.protectionCodeElem.val(res.protectionCode);
    data.formElem.submit();
  }

  // ********** **********
  function showMessage(data, text) {
    data.messageElem.text(text);
    if (text == null) {
      data.messageElem.hide();
    } else {
      data.messageElem.show();
    }
  }
} 
