//// ********** ********** ********** ********** **********

$(document).ready(() => {
  const searchSection = $('.js-search');
  const searchOpenBtn = $('.js-open-search');
  const searchCloseBtn = $('.js-close-search');
  const searchForm = $('.js-search-form');
  const searchForm2 = $('.js-search-form2');

  const advancedSearchCheckBoxAny = $('.advanced-search .search-item input[value="__ANY"][type="checkbox"]');
  const advancedSearchInputCustom = $('.advanced-search .search-item .custom-filter-input');

  searchOpenBtn.click(() => {
    searchSection.addClass('active');
  });

  searchCloseBtn.click(() => {
    searchSection.removeClass('active');
  });

  searchForm.on('submit', (event) => {
    searchForm_onSubmit(event);
  });

  advancedSearchCheckBoxAny.click((event) => {
    advancedSearchCheckBoxAny_onClick(event);
  });

  advancedSearchInputCustom.on('keydown', (event) => {
    advancedSearchInputCustom_onKeydown(event);
  });

  advancedSearchInputCustom.on('mouseup', (event) => {
    advancedSearchInputCustom_onKeydown(event);
  });

  // ********** **********
  function searchForm_onSubmit(event) {
    if (searchForm2.length > 0) {
      const formElem = $(event.currentTarget);
      event.preventDefault();
      const q = $('input[name="q"]', formElem).val();
      $('input[name="q"]', searchForm2).val(q);
      searchForm2.submit();
    }
  }

  // ********** **********
  function advancedSearchCheckBoxAny_onClick(event) {
    const elem = $(event.currentTarget);
    const name = elem.attr('name');

    var checkState = elem.is(':checked');

    if (checkState) {
      $('input[name="' + name + '"]').prop('checked', true);
    }
    else {
      $('input[name="' + name + '"]').prop('checked', false);
    }
  }

  // ********** **********
  function advancedSearchInputCustom_onKeydown(event) {
    const elem = $(event.currentTarget);
    const code = elem.attr('data-code');
    $('#' + code + '__CUSTOM').attr('checked', true);
  }
});

// a fix for the rare cases when the accordion on the phone appears when the page loads
$(window).on('load', () => {
  const searchSection = $('.js-search');
  setTimeout(() => {
    searchSection.addClass('loaded');
  }, 500);
});
