//// ********** ********** ********** ********** **********

// ********** **********
function register_init() {

  // ********** **********
  $(document).ready(() => {
    init();
  });

  // ********** **********
  function init() {

    const agreementNumberElem = $('.agreement-number');
    const checkRulesAgreementElem = $('#checkRulesAgreement');
    const description2Elem = $('#registerDescription2');
    const messageElem = $('#message');

    const data = {
      agreementNumberElem: agreementNumberElem,
      agreementNumberIsLoaded: false,
      checkRulesAgreementElem: checkRulesAgreementElem,
      description2Elem: description2Elem,
      messageElem: messageElem
    };

    checkRulesAgreementElem.prop('checked', false);
    checkRulesAgreementElem.change((event) => { checkRulesAgreementElem_change(data, event); });
  }

  // ********** **********
  function checkRulesAgreementElem_change(data, event) {
    var isChecked = event.currentTarget.checked;
    if (isChecked) {
      data.description2Elem.show(300);
      if (!data.agreementNumberIsLoaded) {
        updateAgreementNumber(data);
      }
    } else {
      data.description2Elem.hide();
    }
  }

  // ********** **********
  function showMessage(data, text) {
    data.messageElem.text(text);
    if (text == null) {
      data.messageElem.hide();
    } else {
      data.messageElem.show();
    }
  }

  // ********** **********
  function updateAgreementNumber(data) {
    data.agreementNumberIsLoaded = true;
    showMessage(data, null);

    $.post(
      baseUrl + 'main/getprotectioncode',
      {},
      (res) => { updateAgreementNumber_result(data, res); });
  }

  // ********** **********
  function updateAgreementNumber_result(data, res) {
    if (!res.ok) {
      data.agreementNumberIsLoaded = false;
      showMessage(data, res.message);
      return;
    }

    $.post(
      baseUrl + 'main/generatenumber',
      { protectionCode: res.protectionCode },
      (res) => { updateAgreementNumber_result2(data, res); });
  }

  // ********** **********
  function updateAgreementNumber_result2(data, res) {
    if (!res.ok) {
      data.agreementNumberIsLoaded = false;
      showMessage(data, res.message);
      return;
    }

    data.agreementNumberElem.text(res.number);
  }
} 
