// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-hide-text').length) {
    hideTexts_init();
  }

  // ********** **********
  function hideText_create(elem) {
    const hideTextTextElem = $('.js-hide-text-text', elem);
    const hideTextTextInnerElem = $('.js-hide-text-text-inner', elem);
    const hideTextMoreElem = $('.js-hide-text-more', elem);
    const showHideBtn = $('.js-hide-text-button', elem);
    const buttonShowTextElem = $('.js-hide-text-button-show', showHideBtn);
    const buttonHideTextElem = $('.js-hide-text-button-hide', showHideBtn);

    let isMoreElemVisible = true;
    if (elem.offsetHeight > 338) {
      const maxHeightText = `${elem.offsetHeight - 68}px`;
      elem.css({ maxHeight: maxHeightText });
      if (hideTextTextInnerElem.offsetHeight < 268) {
        isMoreElemVisible = false;
      }
    }

    elibUtils.showHide(hideTextMoreElem, isMoreElemVisible);

    const hideText = {
      buttonHideTextElem: buttonHideTextElem,
      buttonShowTextElem: buttonShowTextElem,
      elem: elem
    };

    if (isMoreElemVisible) {
      hideText_updateButtonTexts(hideText);

      showHideBtn.click(() => {
        elem.toggleClass('show-text');
        hideText_updateButtonTexts(hideText);
      });
    }

    return hideText;
  }

  // ********** **********
  function hideTexts_init() {
    $('.js-hide-text').each((i, el) => {
      const hideTextElem = $(el);
      hideText_create(hideTextElem);
    });
  }

  // ********** **********
  function hideText_updateButtonTexts(hideText) {
    const hasClassShowText = hideText.elem.hasClass('show-text');
    elibUtils.showHide(hideText.buttonShowTextElem, !hasClassShowText);
    elibUtils.showHide(hideText.buttonHideTextElem, hasClassShowText);
  }
});
