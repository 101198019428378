//// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-docinfo-head').length) {
    init();
  }

  // ********** **********
  function init() {
    const btns = $('.js-docinfo-head');
    const contentElems = $('.js-docinfo-content');

    btns.click((event) => {
      const btn = $(event.target);
      onButtonClick(btn, btns, contentElems);
    });
  }

  // ********** **********
  function onButtonClick(btn, btns, contentElems) {
    const index = btn.attr('data-index');
    const isLoaded = btn.attr('data-is-loaded');
    const contentElem = $(`.js-docinfo-content[data-index='${index}']`);

    btns.removeClass('active');
    btn.addClass('active');

    contentElems.removeClass('active');
    contentElem.addClass('active');

    if (isLoaded == '1') {
      return;
    }

    btn.attr('data-is-loaded', '1');

    const url = btn.attr('data-url');
    const contentInnerElem = $('.js-docinfo-content-inner', contentElem);
    contentInnerElem.load(url);
  }

});

// ********** **********
// * options
//   - actionsService,
//   - actionsPanelSelector,
//   - actionsWarningsPanelSelector,
//   - securityTableSelector,
//   - actionOpenSelector,
//   - documentLocationSelector,
//   - userLoginSelector,
//   - userGroupsSelector,
//   - userNetworkSelector,
//   - userLoginActionsSelector,
//   - texts,
//   - images,
//   - stats,
//   - infoUrl,
//   - externalViewUrl,
//   - loginText,
//   - logoutText,
//   - allowAutoLogin
function loadExternalDocumentActions(options) {

  // ********** **********
  $(document).ready(() => {
    $.ajax({
      url: actionsService,
      type: 'POST',
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      success: function (data) { request_result(data); }
    });
  });

  // ********** **********
  function request_result(data) {

    if (data.Ok) {

      if (data.AutoLoginURL) {
        var url = data.AutoLoginURL.toString();
        window.location.replace(url);
        return;
      }

      if (data.InformationURL) {
        $(options.actionOpenSelector).attr('href', data.InformationURL);

        $(options.actionOpenSelector).click(function () {
          $.postJSON(stats.Open, null);
        });
      }

      $(options.documentLocationSelector).text(data.siteName);
      $(options.userLoginSelector).text(data.userLogin ? data.userLogin : '-');
      $(options.userGroupsSelector).text(data.userGroups ? data.userGroups : '-');
      $(options.userNetworkSelector).text(data.userNetwork);

      $(options.userLoginActionsSelector).empty();
      if (data.loginUrl) {
        var elemLogin = $('<a />', {
          text: loginText,
          href: data.loginUrl.replace('{url}', encodeURIComponent(infoUrl))
        });

        elemLogin.appendTo(options.userLoginActionsSelector);
      }

      if (data.logoutUrl) {
        var elemLogout = $('<a />', {
          text: logoutText,
          href: data.logoutUrl.replace('{url}', encodeURIComponent(infoUrl))
        });

        elemLogout.appendTo(options.userLoginActionsSelector);
      }

      if (Array.isArray(data.actions)) {
        preprocessActions(data.actions, options.externalViewUrl);
        data.actions.forEach((action) => {
          const elem = $('<a />', {
            'class': 'action',
            href: action.url,
            text: action.title
          });

          elem.click(function () {
            let url = stats.Download;
            if (action.type == 'View') {
              url = stats.view;
            }

            $.postJSON(url, null);
          });

          elem.appendTo(options.actionsPanelSelector);
          $(options.actionsPanelSelector).append(' ');
        });
      }

      if (data.fileLengthString != null) {
        const elem2 = $('<span />', { text: ' (' + data.fileLengthString + ')'});
        elem2.appendTo(options.actionsPanelSelector);
      }

      if (Array.isArray(data.actionsWarnings)) {
        data.actionsWarnings.forEach((actionsWarning) => {
          const elem3 = $('<p />');
          $('<img />', { src: options.images.exclamation, alt: '' }).appendTo(elem3);
          $('<span />', { text: actionsWarning }).appendTo(elem3);
          elem3.appendTo(options.actionsWarningsPanelSelector);
        });
      }

      var actionUrls =
      {
        view: getActionUrl(data.actions, 'View'),
        print: null,
        download: getActionUrl(data.actions, 'Download')
      };

      if (Array.isArray(data.security)) {
        const securityTable = $(securityTableSelector);
        data.security.forEach((securityRow) => {
          createSecurityTableRow(securityRow, securityTable, actionUrls);
        });
      }
    }
    else {
      const elem4 = $('<p />');
      $('<img />', { src: options.images.exclamation, alt: '' }).appendTo(elem4);
      $('<span />', { text: data.message }).appendTo(elem4);
      elem4.appendTo(options.actionsWarningsPanelSelector);
    }
  }

  // ********** **********
  function createSecurityTableRow(securityRow, securityTable, actionUrls) {
    const elem = $('<tr />');
    elem.appendTo(securityTable);

    if (securityRow.selected) {
      elem.addClass('active');
    }

    const elemTd1 = $('<td />', {
      text: securityRow.networkFullName,
      title: securityRow.networkFullName
    });
    elemTd1.appendTo(elem);

    const elemTd2 = $('<td />', {
      text: securityRow.userGroupFullName,
      title: securityRow.userGroupFullName
    });
    elemTd2.appendTo(elem);

    const elemTd3 = $('<td />');
    elemTd3.appendTo(elem);

    const divActions = $('<div /');
    divActions.appendTo(elemTd3);

    createSecurityTableRow_action(divActions, securityRow.allowView, securityRow.selected ? actionUrls.view : null, options.texts.view, options.images.view);
    createSecurityTableRow_action(divActions, securityRow.allowPrint, securityRow.selected ? actionUrls.print : null, options.texts.print, options.images.print);
    createSecurityTableRow_action(divActions, securityRow.allowDownload, securityRow.selected ? actionUrls.sownload : null, options.texts.download, options.images.download);
  }

  function createSecurityTableRow_action(divActions, allow, actionUrl, text, imageUrl) {

    if (!allow) {
      return;
    }

    const elemImg = $('<img />', {
      alt: text,
      title: text,
      src: imageUrl
    });

    if (actionUrl) {
      const elemA = $('<a />', {
        href: actionUrl
      });

      elemA.appendTo(securityTableCell);
      elemImg.appendTo(elemA);
    }
    else {
      elemImg.appendTo(securityTableCell);
    }

    divActions.append(' ');
  }

  // ********** **********
  function getActionUrl(actions, type) {
    var action = loadExternalDocumentActions_getAction(actions, type);
    if (action == null) {
      return null;
    }
    else {
      return action.url;
    }
  }

  // ********** **********
  function preprocessActions(actions, externalViewUrl) {
    var a1 = loadExternalDocumentActions_getAction(actions, "View");
    if (a1 != null && a1.inlineUrl != null) {
      a1.url = externalViewUrl;
      a1.inlinerl = null;
    }
  }
}

// ********** **********
// * options
//   - actionsService, 
//   - target, 
//   - errorPanel
function loadExternalDocumentActions_inlineView(options) {

  // ********** **********
  $(document).ready(() => {
    $.ajax({
      url: options.actionsService,
      type: 'POST',
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      success: function (data) { request_result(data); },
      fail: function (jqXHR, textStatus) { $(options.errorPanel).text(textStatus); }
    });
  });

  // ********** **********
  function request_result(data) {
    if (data.Ok) {

      var action = loadExternalDocumentActions_getAction(data.Actions, 'View');
      if (action == null || action.inlineUrl == null) {
        $(errorPanel).text('���������� �������� ��������� �� ��������������. Inline view is not supported');
      }
      else {
        $(target).attr('src', action.inlineUrl);
      }
    }
    else {
      $(errorPanel).text(data.message);
    }
  }
}

// ********** **********
function loadExternalDocumentActions_getAction(actions, type) {
  if (!Array.isArray(actions)) {
    return null;
  }

  let action;
  for (let i = 0; i < actions.length; i++) {
    action = actions[i];
    if (action.type == type) {
      return action;
    }
  }

  return null;
}