// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-accordion').length) {
    accordions_init();
  }

  // ********** **********
  function accordion_init(elem) {

    const contentElem = $('.js-accordion-content', elem);
    const isOpened = elem.hasClass('open');

    const accordion = {
      contentElem: contentElem,
      elem: elem,
      isOpened: isOpened
    };

    $('.js-accordion-head', elem).click(() => {
      elibUtils.expand(accordion, !accordion.isOpened);
    });

    return accordion;
  }

  // ********** **********
  function accordions_init() {
    $('.js-accordion').each((i, elem) => {
      const accordionElem = $(elem);
      accordion_init(accordionElem);
    });
  }
});



