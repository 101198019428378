// ********** ********** ********** ********** **********
// Elib scripts
//
//
const elibUtils = new Object();

(() => {

  // ********** **********
  let formIdCounter = 0;

  // ********** **********
  elibUtils.addRemoveClass = function (el, className, isActive) {
    if (isActive) {
      el.addClass(className);
    }
    else {
      el.removeClass(className);
    }
  };

  // ********** **********
  elibUtils.convertUrl = function (url) {
    if (url.substring(0, 16) === 'key://localhost/') {
      const res1 = baseUrl2 + 'doc/info?key=' + url.substring(16);
      return res1;
    }
    else {
      var u2 = new URL(url, document.baseURI);
      var res2 = u2.href;
      return res2;
    }
  };

  // ********** **********
  // accordion :
  // - elem
  // - contentElem
  // - isOpened
  // - openClass (опционально)
  //
  // options:
  // - instantly (если нужно мгновенное изменение)
  //
  elibUtils.expand = function (accordion, isOpened, options) {
    if (!accordion || !accordion.contentElem || accordion.isOpened == isOpened) {
      return;
    }

    const instantly = options != null && options.instantly != null ? options.instantly : false;

    if (accordion.isOpenedTimeout) {
      clearTimeout(accordion.isOpenedTimeout);
      accordion.isOpenedTimeout = null;
    }

    accordion.isOpened = isOpened;

    if (!instantly) {
      const maxHeight = accordion.contentElem.prop('scrollHeight') + 'px';
      accordion.contentElem.css({ maxHeight: maxHeight, overflow: 'hidden' });
    }

    const openClass = accordion.openClass != null ? accordion.openClass : 'open';
    elibUtils.addRemoveClass(accordion.elem, openClass, isOpened);

    if (!instantly) {
      const ms = isOpened ? 300 : 10;
      accordion.isOpenedTimeout = setTimeout(() => {
        accordion.isOpenedTimeout = null;
        accordion.contentElem.css({ maxHeight: '', overflow: '' });
      }, ms);
    }
  };

  // ********** **********
  elibUtils.editor_fillValues = function (elem, values) {
    elibUtils.editor_processBlocks(elem, (block) => {

      const inputType = block.inputElem.attr('type');
      const v = values[block.inputElem.attr('name')];

      if (inputType == 'checkbox') {
        block.inputElem.prop('checked', v == true);
      }
      else {
        block.inputElem.val(v);
      }
    });
  };

  // ********** **********
  elibUtils.editor_getValues = function (elem) {
    const values = {};
    elibUtils.editor_processBlocks(elem, (block) => {

      const inputType = block.inputElem.attr('type');
      const name = block.inputElem.attr('name');

      if (inputType == 'checkbox') {
        values[name] = block.inputElem.prop('checked') == true;
      }
      else {
        values[name] = block.inputElem.val();
      }
    });

    return values;
  };

  // ********** **********
  elibUtils.editor_init = function (elem) {
    elibUtils.editor_processBlocks(elem, (block) => {
      const id = 'form_auto_id_' + formIdCounter;
      formIdCounter++;

      block.inputElem.attr('id', id);
      block.labelElem.attr('for', id);
    });
  };

  // ********** **********
  elibUtils.editor_processBlocks = function (elem, action) {
    $('.js-editor-block', elem).each((i, elem2) => {

      const inputElem = $('input, textarea', elem2);
      const labelElem = $('label', elem2);
      if (!(labelElem.length == 1 && inputElem.length == 1)) {
        return;
      }

      const block = {
        inputElem: inputElem,
        labelElem: labelElem
      };

      action(block);
    });
  };

  // ********** **********
  elibUtils.formatString = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  };

  // ********** **********
  elibUtils.showHide = function (el, isVisible) {
    if (isVisible) {
      el.show();
    }
    else {
      el.hide();
    }
  };


})();
