//// ********** ********** ********** ********** **********

$(document).ready(() => {

  if ($('.js-user-edit').length) {
    userEdit_init();
  }

  // ********** **********
  function userEdit_init() {

    const baseUrl2 = baseUrl + 'users/' + encodeURIComponent(loginProviderCode);
    const dateFormat = 'dd-mm-yy';

    const commandBtn = $('.js-userEdit-commands button');
    const commandCancelBtn = $('#buttonCancel');
    const commandDeleteBtn = $('#buttonDelete');
    const commandEditBtn = $('#buttonEdit');
    const commandGenerateChangePasswordLinkBtn = $('#buttonGenerateChangePasswordLink');
    const commandPrintBtn = $('#buttonPrint');
    const commandRestoreBtn = $('#buttonRestore');
    const commandSaveBtn = $('#buttonSave');

    const fieldSetUserElem = $('#fieldSetUser');
    const fieldSetUserInputElem = $('#fieldSetUser input');

    const formPrintElem = $('#formPrint');
    const formRedirectElem = $('#formRedirect');

    const generatePasswordBtn = $('#buttonGeneratePassword');

    const panelMessageElem = $('#panelMessage');
    const userCommentsElem = $('#userComments');
    const userEmailElem = $('#userEmail');
    const userIsBlockedElem = $('#userIsBlocked');
    const userIsDeletedElem = $('#userIsDeleted');
    const userLoginElem = $('#userLogin');
    const userNameElem = $('#userName');
    const userPasswordElem = $('#userPassword');
    const userPassword2Elem = $('#userPassword2');
    const userPassword3Elem = $('#userPassword3');
    const userPasswordChangeInfoElem = $('#userPasswordChangeInfo');
    const userPasswordChangeInfoLabelElem = $('label[for="userPasswordChangeInfo"]');
    const userRegistrationTimeElem = $('#userRegistrationTime');
    const userReregistrationTimeElem = $('#userReregistrationTime');

    const userEdit = {
      baseUrl2: baseUrl2,
      commandBtn: commandBtn,
      commandCancelBtn: commandCancelBtn,
      commandDeleteBtn: commandDeleteBtn,
      commandEditBtn: commandEditBtn,
      commandGenerateChangePasswordLinkBtn: commandGenerateChangePasswordLinkBtn,
      commandPrintBtn: commandPrintBtn,
      commandRestoreBtn: commandRestoreBtn,
      commandSaveBtn: commandSaveBtn,
      dateFormat: dateFormat,
      fieldSetUserElem: fieldSetUserElem,
      fieldSetUserInputElem: fieldSetUserInputElem,
      formPrintElem: formPrintElem,
      formRedirectElem: formRedirectElem,
      generatePasswordBtn: generatePasswordBtn,
      isEditMode: false,
      panelMessageElem: panelMessageElem,
      userCommentsElem: userCommentsElem,
      userEmailElem: userEmailElem,
      userIsBlockedElem: userIsBlockedElem,
      userIsDeletedElem: userIsDeletedElem,
      userLoginElem: userLoginElem,
      userNameElem: userNameElem,
      userPasswordElem: userPasswordElem,
      userPassword2Elem: userPassword2Elem,
      userPassword3Elem: userPassword3Elem,
      userPasswordChangeInfoElem: userPasswordChangeInfoElem,
      userPasswordChangeInfoLabelElem: userPasswordChangeInfoLabelElem,
      userRegistrationTimeElem: userRegistrationTimeElem,
      userReregistrationTimeElem: userReregistrationTimeElem
    };

    userEdit_init_buttons(userEdit);
    userEdit_init_datePickers(userEdit);

    userEdit_getUser(userEdit);
  }

  // ********** **********
  function userEdit_init_buttons(userEdit) {
    userEdit.commandCancelBtn.click(() => { userEdit_button_cancel(userEdit); });
    userEdit.commandDeleteBtn.click(() => { userEdit_button_delete(userEdit); });
    userEdit.commandEditBtn.click(() => { userEdit_button_edit(userEdit); });
    userEdit.commandGenerateChangePasswordLinkBtn.click(() => { userEdit_button_generateChangePasswordLink(userEdit); });
    userEdit.commandPrintBtn.click(() => { userEdit_button_print(userEdit); });
    userEdit.commandRestoreBtn.click(() => { userEdit_button_restore(userEdit); });
    userEdit.commandSaveBtn.click(() => { userEdit_button_save(userEdit); });
    userEdit.generatePasswordBtn.click(() => { userEdit_button_generatePassword(userEdit); });
  }

  // ********** **********
  function userEdit_init_datePickers(userEdit) {
    userEdit.userRegistrationTimeElem.datepicker();
    userEdit.userRegistrationTimeElem.datepicker('option', 'dateFormat', userEdit.dateFormat);
    userEdit.userRegistrationTimeElem.datepicker('option', $.datepicker.regional[datepickerLangCode]);

    userEdit.userReregistrationTimeElem.datepicker();
    userEdit.userReregistrationTimeElem.datepicker('option', 'dateFormat', userEdit.dateFormat);
    userEdit.userReregistrationTimeElem.datepicker('option', $.datepicker.regional[datepickerLangCode]);
  }

  // ********** **********
  function userEdit_addOrUpdateUser(userEdit, user, extraData) {

    userEdit_enableEditControls(userEdit, false);
    userEdit.panelMessageElem.text('Идет сохранение...');
    userEdit.commandBtn.hide();

    const apiMethod = login == null ? '/adduser' : '/updateuser';

    var path = userEdit.baseUrl2 + apiMethod;

    $.post(
      path,
      user,
      (res) => { userEdit_getUser_result(userEdit, res, extraData); });
  }

  // ********** **********
  function userEdit_button_cancel(userEdit) {
    userEdit.isEditMode = false;
    userEdit_getUser(userEdit);
  }

  // ********** **********
  function userEdit_button_delete(userEdit) {
    const user = userEdit_createUserObject(userEdit);
    user.isDeleted = true;
    userEdit_addOrUpdateUser(userEdit, user, {});
  }

  // ********** **********
  function userEdit_button_edit(userEdit) {

    userEdit_enableEditControls(userEdit, true);

    userEdit.commandBtn.hide();
    userEdit.commandSaveBtn.show();

    if (login == null) {
      userEdit.userReregistrationTimeElem.datepicker('setDate', defaultReregistrationTime);
      userEdit.userPasswordElem.val(userEdit_generatePassword());
      userEdit.commandCancelBtn.hide();
    } else {
      userEdit.commandCancelBtn.show();
    }

    // isEditMode = true;
  }

  function userEdit_button_generateChangePasswordLink(userEdit) {

    if (login == null) {
      return;
    }

    userEdit_enableEditControls(userEdit, false);
    userEdit.panelMessageElem.text('Выполняется запрос...');
    userEdit.commandBtn.hide();
    const path = userEdit.baseUrl2 + '/generatechangepasswordlink';

    $.post(
      path,
      { login: login },
      (res) => { userEdit_getUser_result(userEdit, res, {}); });
  }

  //**********
  function userEdit_button_generatePassword(userEdit) {
    const p = userEdit_generatePassword(userEdit);
    userEdit.userPasswordElem.val(p);
  }

  // ********** **********
  function userEdit_button_print(userEdit) {
    userEdit.userPassword2Elem.val(userEdit.userPasswordElem.val());
    userEdit.formPrintElem.submit();
  }

  //**********
  function userEdit_button_restore(userEdit) {
    const user = userEdit_createUserObject(userEdit);
    user.isDeleted = false;
    userEdit_addOrUpdateUser(userEdit, user, {});
  }

  //**********
  function userEdit_button_save(userEdit) {

    const user = userEdit_createUserObject(userEdit);

    const extraData = {};
    if (user.password != null && user.password.length > 0) {
      extraData.newPassword = user.password;
    }

    userEdit_addOrUpdateUser(userEdit, user, extraData);
  }

  // ********** **********
  function userEdit_covertJsDateToIso(d) {
    if (d == null) {
      return null;
    }

    const s = d.toISOString();
    return s;
  }

  // ********** **********
  function userEdit_covertIsoDateToJs(d) {
    const d2 = new Date(d);
    return d2;
  }

  // ********** **********
  function userEdit_createUserObject(userEdit) {
    const u = {};
    u.login = userEdit.userLoginElem.val();
    u.password = userEdit.userPasswordElem.val();
    u.name = userEdit.userNameElem.val();
    u.email = userEdit.userEmailElem.val();
    u.comments = userEdit.userCommentsElem.val();
    u.isBlocked = userEdit.userIsBlockedElem.is(':checked');
    u.IsDeleted = userEdit.userIsDeletedElem.css('display') == 'inline';
    u.ReregistrationTime = userEdit_covertJsDateToIso(userEdit.userReregistrationTimeElem.datepicker('getDate'));
    return u;
  }

  // ********** **********
  function userEdit_enableEditControls(userEdit, enable) {
    userEdit.fieldSetUserElem.attr('disabled', !enable);
    userEdit.fieldSetUserInputElem.attr('disabled', !enable);

    userEdit.generatePasswordBtn.attr('disabled', !enable);

    if (enable) {
      userEdit.userPasswordChangeInfoElem.hide();
      userEdit.userPasswordChangeInfoLabelElem.hide();
      userEdit.userRegistrationTimeElem.attr('disabled', true);
    }
  }

  // ********** **********
  function userEdit_generatePassword() {
    const string_length = 8;
    const chars = 'ABCDEFGHIJKLMNPQRSTUVWXTZabcdefghikmnpqrstuvwxyz23456789';
    // All letters except 'O', 'o', 'l', '1', '0'

    let randomstring = '';
    let charCount = 0;
    let numCount = 0;
    let rnum;

    for (let i = 0; i < string_length; i++) {
      // If random bit is 0, there are less than 3 digits already saved, and there are not already 5 characters saved, generate a numeric value. 
      if (Math.floor(Math.random() * 2) == 0 && numCount < 3 || charCount >= 5) {
        rnum = Math.floor(Math.random() * 9) + 1;
        randomstring += rnum;
        numCount += 1;
      } else {
        // If any of the above criteria fail, go ahead and generate an alpha character from the chars string
        rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
        charCount += 1;
      }
    }

    return randomstring;
  }

  // ********** **********
  function userEdit_getUser(userEdit) {
    if (login == null) {
      userEdit_button_edit(userEdit);
    }
    else {
      userEdit_enableEditControls(userEdit, false);
      userEdit.panelMessageElem.text('Идет загрузка...');
      var path = userEdit.baseUrl2 + '/getuser';

      $.post(
        path,
        { login: login },
        (res) => { userEdit_getUser_result(userEdit, res, {}); });
    }
  }

  // ********** **********
  function userEdit_getUser_result(userEdit, res, extraData) {
    userEdit.panelMessageElem.text('');
    if (res.ok) {
      if (res.newUrl != null) {
        userEdit.formRedirectElem.attr('action', res.newUrl);
        userEdit.userPassword3Elem.val(extraData.newPassword);
        userEdit.formRedirectElem.submit();
      }
      else {
        userEdit_updateForm(userEdit, res.user, extraData);
      }

      userEdit.isEditMode = false;
    }
    else {
      userEdit.panelMessageElem.text(res.message);

      if (userEdit.isEditMode) {
        userEdit_button_edit();
      }
    }
  }

  //**********
  function userEdit_updateForm(userEdit, user, extraData) {
    login = user.login;
    userEdit.userLoginElem.val(user.login);
    userEdit.userLoginElem.attr('disabled', true);

    userEdit.userNameElem.val(user.name);
    userEdit.userEmailElem.val(user.email);
    userEdit.userCommentsElem.val(user.comments);

    if (extraData.newPassword != null) {
      password = extraData.newPassword;
    }

    userEdit.userPasswordElem.val(password);

    if (user.passwordChangeInfo != null) {
      userEdit.userPasswordChangeInfoElem.show();
      userEdit.userPasswordChangeInfoElem.val(user.passwordChangeInfo);
      userEdit.userPasswordChangeInfoLabelElem.show();
    } else {
      userEdit.userPasswordChangeInfoElem.hide();
      userEdit.userPasswordChangeInfoElem.val('');
      userEdit.userPasswordChangeInfoLabelElem.hide();
    }

    userEdit.userIsBlockedElem.attr('checked', user.isBlocked);
    userEdit.userIsDeletedElem.css('display', user.isDeleted ? 'inline' : 'none');

    userEdit.userRegistrationTimeElem.datepicker('setDate', userEdit_covertIsoDateToJs(user.registrationTime));
    userEdit.userReregistrationTimeElem.datepicker('setDate', userEdit_covertIsoDateToJs(user.reregistrationTime));

    userEdit.commandEditBtn.show();
    userEdit.commandPrintBtn.show();
    userEdit.commandCancelBtn.hide();
    userEdit.commandSaveBtn.hide();

    if (user.isDeleted) {
      userEdit.commandGenerateChangePasswordLinkBtn.hide();
      userEdit.commandDeleteBtn.hide();
      userEdit.commandRestoreBtn.show();
    } else {
      userEdit.commandGenerateChangePasswordLinkBtn.show();
      userEdit.commandDeleteBtn.show();
      userEdit.commandRestoreBtn.hide();
    }
  }
});
